function loadVideoOnRequesst() {
    const videoElement = document.getElementsByClassName('videoPlayer');
    const youtubeBaseUrl = 'https://www.youtube.com/embed/';
    const vimeoBaseUrl = 'https://player.vimeo.com/video/';

    function updateVideo() {
        const videoUrl = this.getAttribute('data-video-url');
        const videoData = processVideoData(videoUrl);
        const id = videoData[0];
        const source = videoData[1];
        const baseUrl = source ? youtubeBaseUrl : vimeoBaseUrl;

        this.classList.add("embed-responsive-16by9"); // adds responsive styling to the video iframe via a css class
        this.innerHTML =
            '<iframe src="' +
            baseUrl
            + id
            + '?autoplay=1" ' +
            'width="560" height="315" ' +
            'frameborder="0" ' +
            'allowfullscreen ' +
            'referrerpolicy="strict-origin-when-cross-origin"\n' +
            'allowfullscreen ' +
            'allow="autoplay">' +
            '</iframe>';
    }

    for (i = 0; i < videoElement.length; i++) {
        videoElement[i].addEventListener("click", updateVideo);
    }
}


function processVideoData(videoUrl) {
    // Determines whether the video is hosted via vimeo or youtube
    const isYoutube = videoUrl.includes('youtube');
    // Splits the video url into an array
    const videoDataArray = videoUrl.split('/');
    // Gets last in array for videoID
    let getVideoID = videoDataArray[videoDataArray.length-1];

    if (isYoutube === true) {
        getVideoID = getVideoID.replace('watch?v=', '');
    }

    return [getVideoID, isYoutube];
}

loadVideoOnRequesst();
